<template>
  <v-card class="pl-16 d-flex flex-column justify-end" elevation="0">
    <v-toolbar flat class="mb-5">
      <v-toolbar-title>
        <div class="d-flex align-center pl-5">
          <v-icon color="#A3AEFF" class="mr-3">mdi-eye-outline</v-icon>
          <p class="text-body-1 ma-0 font-weight-bold">Tutoriales</p>
          <v-icon class="mr-2 ml-10" small color="#6F50FF"
            >mdi-help-circle-outline</v-icon
          >
          <p class="text-caption ma-0 mr-10">
            Aprende fácilmente a navegar y a utilizar todas las herramientas que
            Reclutachat tiene para ti
          </p>
        </div>
      </v-toolbar-title>
    </v-toolbar>
    <v-tabs vertical justify-tab-title="right" class="pl-5 d-flex justify-end">
      <v-tab-item style="width: 100%">
        <v-card flat>
          <video
            width="100%"
            height="100%"
            style="top: 0; left: 0"
            loop
            playsinline
            controls
          >
            <source
              src="https://firebasestorage.googleapis.com/v0/b/reclutachatlive.appspot.com/o/media%2Freclutachat_como%20crear%20una%20vacante.mp4?alt=media&token=2011e86c-c31f-4702-b3de-c06ab031eced"
              type="video/mp4"
            />
          </video>
        </v-card>
      </v-tab-item>
      <v-tab-item style="width: 100%">
        <v-card flat>
          <video
            width="100%"
            height="100%"
            style="top: 0; left: 0"
            loop
            playsinline
            controls
          >
            <source
              src="https://firebasestorage.googleapis.com/v0/b/reclutachatlive.appspot.com/o/media%2Farchivar%20o%20borrar%20una%20vacante%20anim.mp4?alt=media&token=a0178417-30c9-4989-bd02-8d99aa462a09"
              type="video/mp4"
            />
          </video>
        </v-card>
      </v-tab-item>
      <v-tab-item style="width: 100%">
        <v-card flat>
          <video
            width="100%"
            height="100%"
            style="top: 0; left: 0"
            loop
            playsinline
            controls
          >
            <source
              src="https://firebasestorage.googleapis.com/v0/b/reclutachatlive.appspot.com/o/media%2Freclutachat_desarchivar%20vacante%20anim.mp4?alt=media&token=b60adbb8-ad6e-4e9d-830f-9964b920b5e4"
              type="video/mp4"
            />
          </video>
        </v-card>
      </v-tab-item>
      <v-tab-item style="width: 100%">
        <v-card flat>
          <video
            width="100%"
            height="100%"
            style="top: 0; left: 0"
            loop
            playsinline
            controls
          >
            <source
              src="https://firebasestorage.googleapis.com/v0/b/reclutachatlive.appspot.com/o/media%2Freclutachat_como%20editar%20una%20vacante%20anim.mp4?alt=media&token=e23ec980-d917-4d0e-a5c8-90c92c3e895f"
              type="video/mp4"
            />
          </video>
        </v-card>
      </v-tab-item>
      <v-tab-item style="width: 100%">
        <v-card flat>
          <video
            width="100%"
            height="100%"
            style="top: 0; left: 0"
            loop
            playsinline
            controls
          >
            <source
              src="https://firebasestorage.googleapis.com/v0/b/reclutachatlive.appspot.com/o/media%2Fcandidatos%20citados_anim%20video_auido%20ok.mp4?alt=media&token=c592f9a9-883c-4ff1-8396-1abf22b9d6d9"
              type="video/mp4"
            />
          </video>
        </v-card>
      </v-tab-item>
      <v-tab-item style="width: 100%">
        <v-card flat>
          <video
            width="100%"
            height="100%"
            style="top: 0; left: 0"
            loop
            playsinline
            controls
          >
            <source
              src="https://firebasestorage.googleapis.com/v0/b/reclutachatlive.appspot.com/o/media%2Freclutachat%20como%20crear%20una%20zona.mp4?alt=media&token=fe25d4c9-0990-48f5-a02b-649b89b03c6f"
              type="video/mp4"
            />
          </video>
        </v-card>
      </v-tab-item>
      <v-tab-item style="width: 100%">
        <v-card flat>
          <video
            width="100%"
            height="100%"
            style="top: 0; left: 0"
            loop
            playsinline
            controls
          >
            <source
              src="https://firebasestorage.googleapis.com/v0/b/reclutachatlive.appspot.com/o/media%2Freclutachat_como%20consultar%20la%20base%20de%20datos.mp4?alt=media&token=07f455d1-a946-481f-9c81-15569066ea64"
              type="video/mp4"
            />
          </video>
        </v-card>
      </v-tab-item>
      <v-tab-item style="width: 100%">
        <v-card flat>
          <video
            width="100%"
            height="100%"
            style="top: 0; left: 0"
            loop
            playsinline
            controls
          >
            <source
              src="https://firebasestorage.googleapis.com/v0/b/reclutachatlive.appspot.com/o/media%2Freclutachat_interactuar%20con%20el%20bot%20anim.mp4?alt=media&token=36e6f85d-e7b1-44ca-97dc-7f0888a8fad1"
              type="video/mp4"
            />
          </video>
        </v-card>
      </v-tab-item>
      <div style="width: 35vw; height: 100%">
        <v-tab class="pt-3 pb-3 d-flex justify-start" style="width: 100%"
          ><p class="pa-0 ma-0 text-capitalize">
            <v-icon color="#6200EE" class="mr-3" small>mdi-video-outline</v-icon
            ><stong class="font-weight-bold">01. </stong>¿Cómo crear una vacante?
          </p></v-tab
        >
        <v-tab class="pt-3 pb-3 d-flex justify-start" style="width: 100%"
          ><p class="pa-0 ma-0 text-capitalize">
            <v-icon color="#6200EE" class="mr-3" small>mdi-video-outline</v-icon
            ><stong class="font-weight-bold">02. </stong>¿Cómo archivar o borrar una vacante?
          </p></v-tab
        >
        <v-tab class="pt-3 pb-3 d-flex justify-start" style="width: 100%"
          ><p class="pa-0 ma-0 text-capitalize">
            <v-icon color="#6200EE" class="mr-3" small>mdi-video-outline</v-icon
            ><stong class="font-weight-bold">03. </stong>¿Cómo desarchivar una vacante?
          </p></v-tab
          >
          <v-tab class="pt-3 pb-3 d-flex justify-start" style="width: 100%"
            ><p class="pa-0 ma-0 text-capitalize">
              <v-icon color="#6200EE" class="mr-3" small>mdi-video-outline</v-icon
              ><stong class="font-weight-bold">04. </stong>¿Cómo editar una vacante?
            </p></v-tab
          >
        <v-tab class="pt-3 pb-3 d-flex justify-start" style="width: 100%"
          ><p class="pa-0 ma-0 text-capitalize">
            <v-icon color="#6200EE" class="mr-3" small>mdi-video-outline</v-icon
            ><stong class="font-weight-bold">05. </stong>¿Cómo consultar los candidatos citados?
          </p></v-tab
        >
        <v-tab class="pt-3 pb-3 d-flex justify-start" style="width: 100%"
          ><p class="pa-0 ma-0 text-capitalize">
            <v-icon color="#6200EE" class="mr-3" small>mdi-video-outline</v-icon
            ><stong class="font-weight-bold">06. </stong>¿Cómo crear una zona nueva?
          </p></v-tab
        >
        <v-tab class="pt-3 pb-3 d-flex justify-start" style="width: 100%"
          ><p class="pa-0 ma-0 text-capitalize">
            <v-icon color="#6200EE" class="mr-3" small>mdi-video-outline</v-icon
            ><stong class="font-weight-bold">07. </stong>¿Cómo consultar la base de datos?
          </p></v-tab
        >
        <v-tab class="pt-3 pb-3 d-flex justify-start" style="width: 100%"
          ><p class="pa-0 ma-0 text-capitalize">
            <v-icon color="#6200EE" class="mr-3" small>mdi-video-outline</v-icon
            ><stong class="font-weight-bold">08. </stong>¿Conociendo el bot de Reclutachat?
          </p></v-tab
        >
      </div>
    </v-tabs>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      tab: null,
    };
  },
};
</script>

<style>
.modal-fade-enter,
.modal-fade-leave-active {
  opacity: 0;
}
.modal-fade-enter-active,
modal-fade-leave-active {
  transition: opacity 0.5s ease;
}
.v-application .primary--text {
  color: #6200ee !important;
  caret-color: #6200ee !important;
}
</style>
